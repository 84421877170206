export const setTableHtml = (array) => {
  const formateData = array.map(item => {
    return item
  })
  const spanMap = formateData.reduce((prev, next, index) => {
    const { changeNo, changeTime, changeType } = next
    const changeNoKey = `changeNo_${changeNo}`
    const changeTimeKey = `changeNo_changeTime_${changeNo}_${changeTime}`
    const changeTypeKey = `changeNo_changeTime_changeType_${changeNo}_${changeTime}_${changeType}`
    if (!prev[changeNoKey]) {
      const num = findKeyNum('changeNo', changeNo)
      prev[changeNoKey] = `${index}_${num}`
    }
    if (!prev[changeTimeKey]) {
      const num = findKeyNum('changeNo_changeTime', `${changeNo}_${changeTime}`)
      prev[changeTimeKey] = `${index}_${num}`
    }
    if (!prev[changeTypeKey]) {
      const num = findKeyNum('changeNo_changeTime_changeType', `${changeNo}_${changeTime}_${changeType}`)
      prev[changeTypeKey] = `${index}_${num}`
    }
    return prev
  }, {})
  // console.log(spanMap)
  function findValue(key, next) {
    const arr = key.split('_')
    const v = arr.reduce((p, n) => {
      p.push(next[n])
      return p
    }, []).join('_')
    return v
  }

  function findKeyNum(key, value) {
    return formateData.reduce((prev, next) => {
      if (findValue(key, next) === value) {
        prev += 1
      }
      return prev
    },0)
  }
  const tableKey = ['changeNo','changeTime','changeType','serialNo','nodeDesc','planPayTime','payTerms','payAmount','totalAmount','payProportion','actualPayAmount','payTotalProportion','payState']
  const html = formateData.map((item, index) => {
    return `<tr>${
      tableKey.map(cItem => {
        const spanArr = ['changeNo', 'changeTime', 'changeType']
        const keyMap = {
          changeNo: 'changeNo',
          changeTime: 'changeNo_changeTime',
          changeType: 'changeNo_changeTime_changeType'
        }
        if (spanArr.includes(cItem)) {
          const [startIndex, num] = spanMap[`${keyMap[cItem]}_${findValue(keyMap[cItem], item)}`].split("_")
          if (Number(startIndex) === index) {
            // 1 就正常显示 不合并
            if (Number(num) === 1) {
              if(cItem === 'changeType') {
                return `<td >${item[cItem] === 1 ? '正变更' : '负变更'}</td>`
              }else if(cItem === 'payState'){
                
                switch(item[cItem]){
                  case '26':
                    return `<td >未支付</td>`
                  case '27':
                    return `<td >已申请</td>`
                  case '23':
                    return `<td >待支付</td>`
                  case '24':
                    return `<td >已请款</td>`
                  case '25':
                    return `<td >已支付</td>`
                }
              }else {
                return `<td >${item[cItem]===null?"" : item[cItem]}</td>`
              }
            }
            if(cItem === 'changeType') {
              return `<td rowspan='${num}'>${item[cItem] === 1 ? '正变更' : '负变更'}</td>`
            }else{
              return `<td rowspan='${num}'>${item[cItem]===null?"" : item[cItem]}</td>`
            }
          }
          return null
        }
        if(cItem === 'payState'){   
          switch(item[cItem]){
            case 26:
              return `<td >未支付</td>`
            case 27:
              return `<td >已申请</td>`
            case 23:
              return `<td >待支付</td>`
            case 24:
              return `<td >已请款</td>`
            case 25:
              return `<td >已支付</td>`
          }
        }else {
          return `<td >${item[cItem]===null?"" : item[cItem]}</td>`
        }
      }).join('')
    }</tr>`
  }).join('')
  const tableHtml = `<table width="100%" cellpadding="0" cellspacing="0" border="1">
      <tr>
        <th rowspan="2">变更单号</th>
        <th rowspan="2">变更时间</th>
        <th rowspan="2">正负变更</th>
        <th rowspan="2">序号</th>
        <th colspan="2">支付节点</th>
        <th rowspan="2">支付条件</th>
        <th rowspan="2">支付金额(元)</th>
        <th rowspan="2">变更总金额(元)</th>
        <th rowspan="2">节点支付比例</th>
        <th rowspan="2">实际支付金额(元)</th>
        <th rowspan="2">实际累计支付比例</th>
        <th rowspan="2">支付状态</th>
      </tr>
      <tr>
        <th rowspan="1">节点描述</th>
        <th rowspan="1">计划支付时间</th>
      </tr>
      ${html}
    </table>`
  // console.log('test',  tableHtml)
  return tableHtml
}