import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch } from 'vue';
import useTablePagination from '@/hooks/pagination';
import printJS from 'print-js';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import uploadExl from '@/components/uploadExl.vue';
import { selectBillByPagination, selectAllBill } from '@/api/material';
import { selectContractByParam, selectPayRecordByPagination, selectPayRecordByAll } from '@/api/contract';
import { useStore } from 'vuex';
import Cookies from "js-cookie";
import { setTableHtml } from '@/utils/setTableHtml';
//表头
const columns = [{
  title: '序号',
  dataIndex: 'serialNo',
  width: 80,
  align: 'center',
  fixed: 'left'
}, {
  title: '收支节点',
  // className: 'column-money',
  dataIndex: 'payNode',
  fixed: 'left',
  children: [{
    title: '节点描述',
    dataIndex: 'nodeDesc',
    // key: 'companyAddress',
    width: 320,
    align: 'center' // slots: { customRender: 'nodeDescriptor' },

  }, {
    title: '计划收支时间',
    dataIndex: 'planPayTime',
    width: 120,
    align: 'center' // key: 'paymentTime',
    // slots: { customRender: 'paymentTime' },

  }]
}, {
  title: '收支条件',
  dataIndex: 'payTerms',
  width: 150,
  align: 'center'
}, {
  title: '收支金额(元)',
  dataIndex: 'payAmount',
  width: 120,
  align: 'center'
}, {
  title: '合同总金额(元)',
  dataIndex: 'totalAmount',
  width: 150,
  align: 'center'
}, {
  title: '节点收支比例',
  dataIndex: 'payProportion',
  slots: {
    customRender: 'payProportion'
  },
  width: 120,
  align: 'center'
}, {
  title: '节点备注',
  dataIndex: 'remark',
  slots: {
    customRender: 'remark'
  },
  width: 200,
  align: 'center'
}, {
  title: '实际收支金额(元)',
  dataIndex: 'actualPayAmount',
  width: 150,
  align: 'center'
}, {
  title: '实际累计收支比例',
  dataIndex: 'payTotalProportion',
  slots: {
    customRender: 'payTotalProportion'
  },
  width: 150,
  align: 'center'
}, {
  title: '收支状态',
  dataIndex: 'payState',
  slots: {
    customRender: 'payState'
  },
  width: 90,
  align: 'center'
}];
export default defineComponent({
  components: {
    uploadExl
  },

  setup() {
    const formState = reactive({
      contractName: null,
      contractNo: null
    });

    const mergeCells = (text, data, key, index) => {
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1][key]) {
        return 0;
      }

      let rowSpan = 1; // 判断下一行是否相等

      for (let i = index + 1; i < data.length; i++) {
        if (text !== data[i][key]) {
          break;
        }

        rowSpan++;
      } // console.log(text,rowSpan)


      return rowSpan;
    };

    const mergeCells1 = (text, data, key, index) => {
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1][key] && data[index].changeNo === data[index - 1].changeNo) {
        return 0;
      }

      let rowSpan = 1; // 判断下一行是否相等

      for (let i = index + 1; i < data.length; i++) {
        if (data[index].changeNo !== data[i].changeNo) {
          break;
        }

        if (text !== data[i][key]) {
          break;
        }

        rowSpan++;
      }

      return rowSpan;
    };

    const mergeCells2 = (text, data, key, index) => {
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1][key] && data[index].changeNo === data[index - 1].changeNo && data[index].changeTime === data[index - 1].changeTime) {
        return 0;
      }

      let rowSpan = 1; // 判断下一行是否相等

      for (let i = index + 1; i < data.length; i++) {
        if (data[index].changeNo !== data[i].changeNo) {
          break;
        }

        if (data[index].changeTime !== data[i].changeTime) {
          break;
        }

        if (text !== data[i][key]) {
          break;
        }

        rowSpan++;
      }

      return rowSpan;
    };

    const columns1 = [{
      title: '变更单号',
      dataIndex: 'changeNo',
      customRender: ({
        text,
        index
      }) => {
        const obj = {
          children: text !== null ? text : "",
          attrs: {}
        };
        obj.attrs.rowSpan = mergeCells(text, tableData1.value, "changeNo", index);
        return obj;
      },
      width: 180,
      align: 'center',
      fixed: 'left'
    }, {
      title: '变更时间',
      dataIndex: 'changeTime',
      customRender: ({
        text,
        index
      }) => {
        const obj = {
          children: text !== null ? text : "",
          attrs: {}
        };
        obj.attrs.rowSpan = mergeCells1(text, tableData1.value, "changeTime", index);
        return obj;
      },
      width: 120,
      align: 'center',
      fixed: 'left'
    }, {
      title: '正负变更',
      dataIndex: 'changeType',
      customRender: ({
        text,
        index
      }) => {
        const obj = {
          children: text == 0 ? '负变更' : "正变更",
          attrs: {}
        };
        obj.attrs.rowSpan = mergeCells2(text, tableData1.value, "changeType", index);
        return obj;
      },
      width: 100,
      align: 'center' // slots: { customRender: 'changeType' },

    }, {
      title: '序号',
      dataIndex: 'serialNo',
      width: 70,
      align: 'center'
    }, {
      title: '收支节点',
      // className: 'column-money',
      dataIndex: 'payNode',
      children: [{
        title: '节点描述',
        dataIndex: 'nodeDesc',
        // key: 'companyAddress',
        width: 320,
        align: 'center' // slots: { customRender: 'nodeDescriptor' },

      }, {
        title: '计划收支时间',
        dataIndex: 'planPayTime',
        width: 120,
        align: 'center' // key: 'paymentTime',
        // slots: { customRender: 'paymentTime' },

      }]
    }, {
      title: '收支条件',
      dataIndex: 'payTerms',
      width: 180,
      align: 'center'
    }, {
      title: '收支金额(元)',
      dataIndex: 'payAmount',
      width: 150,
      align: 'center'
    }, {
      title: '变更总金额(元)',
      dataIndex: 'totalAmount',
      width: 180,
      align: 'center'
    }, {
      title: '节点收支比例',
      dataIndex: 'payProportion',
      slots: {
        customRender: 'payProportion'
      },
      width: 180,
      align: 'center'
    }, {
      title: '节点备注',
      dataIndex: 'remark',
      width: 200,
      align: 'center'
    }, {
      title: '实际收支金额(元)',
      dataIndex: 'actualPayAmount',
      width: 180,
      align: 'center'
    }, {
      title: '实际累计收支比例',
      dataIndex: 'payTotalProportion',
      slots: {
        customRender: 'payTotalProportion'
      },
      width: 180,
      align: 'center'
    }, {
      title: '收支状态',
      dataIndex: 'payState',
      slots: {
        customRender: 'payState'
      },
      width: 100,
      align: 'center'
    }]; //表格

    const tableData = ref([]); // const tableData = ref ([
    //   {
    //     serialNo: '1',
    //     nodeDescriptor: 'John',
    //     paymentTime: '2021-07-08',
    //     paymentTerms: 'Brown',
    //     paymentAmount: 32,
    //     totalContractAmount: 32,
    //     paymentRatio:10.00,
    //     actualPayAmount: 32,
    //     payTotalProportion: 32,
    //     payState: 1,
    //   }, 
    // ])
    // const tableData1 = ref ([
    //   {
    //     changeNo:"123456",
    //     changeTime:"2021-07-08",
    //     changeType:1,
    //     serialNo: '1',
    //     nodeDesc: 'John',
    //     planPayTime: '2021-07-08',
    //     payTerms: 'Brown',
    //     payAmount: 32,
    //     totalAmount: 32,
    //     payProportion:10.00,
    //     actualPayAmount: 32,
    //     payTotalProportion: 32,
    //     payState: 1,
    //   }, 
    //   {
    //     changeNo:"123456",
    //     changeTime:"2021-07-08",
    //     changeType:1,
    //     serialNo: '2',
    //     nodeDesc: 'John',
    //     planPayTime: '2021-07-08',
    //     payTerms: 'Brown',
    //     payAmount: 32,
    //     totalAmount: 32,
    //     payProportion:10.00,
    //     actualPayAmount: 32,
    //     payTotalProportion: 32,
    //     payState: 1,
    //   }, 
    //   {
    //     changeNo:"123456",
    //     changeTime:"2021-07-08",
    //     changeType:1,
    //     serialNo: '3',
    //     nodeDesc: 'John',
    //     planPayTime: '2021-07-08',
    //     payTerms: 'Brown',
    //     payAmount: 32,
    //     totalAmount: 32,
    //     payProportion:10.00,
    //     actualPayAmount: 32,
    //     payTotalProportion: 32,
    //     payState: 1,
    //   }, 
    //   {
    //     changeNo:"123456",
    //     changeTime:"2021-07-09",
    //     changeType:1,
    //     serialNo: '1',
    //     nodeDesc: 'John',
    //     planPayTime: '2021-07-08',
    //     payTerms: 'Brown',
    //     payAmount: 32,
    //     totalAmount: 32,
    //     payProportion:10.00,
    //     actualPayAmount: 32,
    //     payTotalProportion: 32,
    //     payState: 1,
    //   }, 
    //   {
    //     changeNo:"123456",
    //     changeTime:"2021-07-09",
    //     changeType:0,
    //     serialNo: '2',
    //     nodeDesc: 'John',
    //     planPayTime: '2021-07-08',
    //     payTerms: 'Brown',
    //     payAmount: 32,
    //     totalAmount: 32,
    //     payProportion:10.00,
    //     actualPayAmount: 32,
    //     payTotalProportion: 32,
    //     payState: 1,
    //   }, 
    //   {
    //     changeNo:"123453",
    //     changeTime:"2021-07-09",
    //     changeType:0,
    //     serialNo: '2',
    //     nodeDesc: 'John',
    //     planPayTime: '2021-07-08',
    //     payTerms: 'Brown',
    //     payAmount: 32,
    //     totalAmount: 32,
    //     payProportion:10.00,
    //     actualPayAmount: 32,
    //     payTotalProportion: 32,
    //     payState: 1,
    //   }, 
    //   {
    //     changeNo:"123453",
    //     changeTime:"2021-07-09",
    //     changeType:0,
    //     serialNo: '2',
    //     nodeDesc: 'John',
    //     planPayTime: '2021-07-08',
    //     payTerms: 'Brown',
    //     payAmount: 32,
    //     totalAmount: 32,
    //     payProportion:10.00,
    //     actualPayAmount: 32,
    //     payTotalProportion: 32,
    //     payState: 1,
    //   }, 
    //   {
    //     changeNo:"123453",
    //     changeTime:"2021-07-10",
    //     changeType:1,
    //     serialNo: '2',
    //     nodeDesc: 'John',
    //     planPayTime: '2021-07-08',
    //     payTerms: 'Brown',
    //     payAmount: 32,
    //     totalAmount: 32,
    //     payProportion:10.00,
    //     actualPayAmount: 32,
    //     payTotalProportion: 32,
    //     payState: 1,
    //   }, 
    //   {
    //     changeNo:"123453",
    //     changeTime:"2021-07-10",
    //     changeType:0,
    //     serialNo: '2',
    //     nodeDesc: 'John',
    //     planPayTime: '2021-07-08',
    //     payTerms: 'Brown',
    //     payAmount: 32,
    //     totalAmount: 32,
    //     payProportion:10.00,
    //     actualPayAmount: 32,
    //     payTotalProportion: 32,
    //     payState: 1,
    //   }, 
    // ])

    const tableData1 = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;

      if (formState.contractName) {
        getTableData();
      }
    }; // const tableAllData = ref([])


    const getAllTableData = () => {
      console.log(formState.contractName, 11111111);
      return new Promise((resolve, reject) => {
        selectPayRecordByAll({
          contractName: formState.contractName,
          contractNo: formState.contractNo
        }).then(res => {
          resolve(res.data);
        });
      }).catch(error => {
        console.log(error);
        reject(error.data);
      });
    }; //打印


    const handlePrint = () => {
      getAllTableData().then(res => {
        const arr = [];
        const arr1 = [];
        if (res.rows) res.rows.forEach(item => {
          arr.push({ ...item
          });
        });
        if (res.list) res.list.forEach(item => {
          arr1.push({ ...item
          });
        }); // 新建一个 DOM

        const div = document.createElement('div');
        const tableBox = 'tableBox';
        div.id = tableBox;
        const tableKey = ['serialNo', 'nodeDesc', 'planPayTime', 'payTerms', 'payAmount', 'totalAmount', 'payProportion', 'remark', 'actualPayAmount', 'payTotalProportion', 'payState'];
        const html = arr.map(item => {
          return `<tr>
            ${tableKey.map(cItem => {
            if (cItem === 'payState') {
              switch (item[cItem]) {
                case 26:
                  return `<td >未支付</td>`;

                case 27:
                  return `<td >已申请</td>`;

                case 23:
                  return `<td >待支付</td>`;

                case 24:
                  return `<td >已请款</td>`;

                case 25:
                  return `<td >已支付</td>`;
              }
            } else {
              return `<td >${item[cItem] === null ? "" : item[cItem]}</td>`;
            }
          })}
          </tr>`;
        }).join('');
        const tableHtml = `<table width="100%" cellpadding="0" cellspacing="0" border="1">
          <tr>
            <th rowspan="2">序号</th>
            <th colspan="2">收支节点</th>
            <th rowspan="2">收支条件</th>
            <th rowspan="2">收支金额(元)</th>
            <th rowspan="2">合同总金额(元)</th>
            <th rowspan="2">节点收支比例</th>
            <th rowspan="2">节点备注</th>
            <th rowspan="2">实际收支金额(元)</th>
            <th rowspan="2">实际累计收支比例</th>
            <th rowspan="2">收支状态</th>
          </tr>
          <tr>
            <th rowspan="1">节点描述</th>
            <th rowspan="1">计划收支时间</th>
          </tr>
          ${html}
        </table>`;
        div.innerHTML = `<h3>合同名称:${arr.length ? arr[0].contractName : arr[1].contractName}</h3><h3>收支计划</h3>${tableHtml}<h3>变更收支计划</h3>${setTableHtml(arr1)}`;
        document.querySelector('body').appendChild(div);
        printJS('tableBox', 'html');
        div.remove();
      });
    }; //下载


    const handleDownExcel = () => {
      getAllTableData().then(res => {
        const arr = [];
        const arr1 = [];
        if (res.rows) res.rows.forEach(item => {
          arr.push({ ...item
          });
        });
        if (res.list) res.list.forEach(item => {
          arr1.push({ ...item
          });
        });

        require.ensure([], () => {
          const {
            export_json_to_excel2
          } = require("@/assets/excel/Export2Excel");

          const tHeader = ["序号", "节点描述", "计划收支时间", "收支条件", "收支金额(元)", "合同总金额(元)", "节点收支比例", "节点备注", "实际收支金额(元)", "实际累计收支比例", "收支状态"]; // 导出的excel表头名信息

          const tHeader1 = ["变更单号", "变更时间", "正负变更", "序号", "节点描述", "计划收支时间", "收支条件", "收支金额(元)", "变更总金额(元)", "节点收支比例", "节点备注", "实际收支金额(元)", "实际累计收支比例", "收支状态"]; // 导出的excel表头名信息

          const titleNameArr = [`收支计划--${arr.length ? arr[0].contractName : arr[1].contractName}`, `变更收支计划--${arr.length ? arr[0].contractName : arr[1].contractName}`]; // 导出的excel表头名信息

          const filterVal = ['serialNo', 'nodeDesc', 'planPayTime', 'payTerms', 'payAmount', 'totalAmount', 'payProportion', 'remark', 'actualPayAmount', 'payTotalProportion', 'payState']; // 导出的excel表头字段名，需要导出表格字段名

          const filterVal1 = ['changeNo', 'changeTime', 'changeType', 'serialNo', 'nodeDesc', 'planPayTime', 'payTerms', 'payAmount', 'totalAmount', 'payProportion', 'remark', 'actualPayAmount', 'payTotalProportion', 'payState']; // 导出的excel表头字段名，需要导出表格字段名

          const data1 = formatJson(filterVal, arr);
          const data = formatJson(filterVal1, arr1);
          const allData = [data1, data]; // console.log('allData',allData)

          export_json_to_excel2(tHeader, tHeader1, titleNameArr, allData, `收支记录--${arr.length ? arr[0].contractName : arr[1].contractName}`, arr1); // 导出的表格名称，根据需要自己命名
        });
      });
    }; //格式转换


    const formatJson = (filterVal, jsonData) => {
      return jsonData.map(v => filterVal.map(j => {
        if (j === 'changeType') {
          if (v[j] === 1) {
            return '正变更';
          } else {
            return '负变更';
          }
        } else if (j === 'payState') {
          switch (v[j]) {
            case 26:
              return `未支付`;

            case 27:
              return `已申请`;

            case 23:
              return `待支付`;

            case 24:
              return `已请款`;

            case 25:
              return `已支付`;
          }
        } else {
          return v[j];
        }
      }));
    }; //获取表格数据


    const getTableData = async () => {
      const param = {
        pageNumber: current.value ? current.value : 1,
        limit: pageSize.value ? pageSize.value : 10,
        contractName: formState.contractName,
        contractNo: formState.contractNo
      };
      loading.value = true;

      try {
        const res = await selectPayRecordByPagination(param);

        if (res.code === 200) {
          const arr = [];
          const arr1 = [];
          tableData.value = [];
          tableData1.value = [];
          if (res.data.rows) res.data.rows.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          if (res.data.list) res.data.list.forEach(item => {
            arr1.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          Object.assign(tableData1.value, arr1);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    };

    const store = useStore(); //是否是管理员

    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin); //查询合同名称

    const contractNameData = ref([]);
    const contractNoData = ref([]);

    const handleSearch = (val, labelName) => {
      fetchForMat(val, labelName);
    };

    const fetchForMat = async (contractName, labelName) => {
      let res = await selectContractByParam({
        projectName: null,
        [labelName]: contractName,
        type: 1
      }); // console.log(res)

      try {
        contractNameData.value = [];
        contractNoData.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            contractNameData.value.push({
              value: ixt.contractName,
              text: ixt.contractName,
              contractNo: ixt.contractNo
            });
            contractNoData.value.push({
              value: ixt.contractNo,
              text: ixt.contractNo,
              contractName: ixt.contractName
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleGetTableData = val => {
      formState.contractNo = null;

      if (!val) {
        tableData.value = [];
        tableData1.value = [];
      } else {
        current.value = 1;
        pageSize.value = 10;
        activeKey.value = '收支计划';
        getTableData();
      }
    };

    const handleGetTableDataNo = val => {
      formState.contractName = null;

      if (!val) {
        tableData.value = [];
        tableData1.value = [];
      } else {
        current.value = 1;
        pageSize.value = 10;
        activeKey.value = '收支计划';
        getTableData();
      }
    };

    const activeKey = ref('收支计划'); // onMounted(()=>{
    //   getTableData()
    // })

    return {
      formState,
      paginationConfig,
      columns,
      columns1,
      tableData,
      loading,
      onPageChange,
      handlePrint,
      handleDownExcel,
      isCompanyAdmin,
      contractNameData,
      handleSearch,
      handleGetTableData,
      tableData1,
      handleGetTableDataNo,
      contractNoData,
      activeKey
    };
  }

});